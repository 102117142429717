import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Single Leg Squats 12-12-12/leg (trailing leg on box/bench, touch knee to
floor)`}</p>
    <p>{`Hamstring Curls 12-12-12 (using stab ball, foam roller or med ball)`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`DB Step Ups/leg (30/20’s, 24/20″)`}</p>
    <p>{`Toes thru Rings`}</p>
    <p>{`Squats holding plate (45/25`}{`#`}{`)`}</p>
    <p>{`Burpees onto plate (45/25`}{`#`}{`)`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Reminder: Tomrrow is the Eclipse so traffic downtown around the
Ville may be bad between 12-4.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`True Grit 5, our annual 4 person team competition, will take place
this year on November 4th!  Get your teams together now.  More info to
come.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program starts August 26th.  Email Eric for more
details: Fallscitystrength\\@gmail.com`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      